.alignleft {
  text-align: left;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

img {
  height: auto;
  max-width: 100%;
}

.max-mobile {
  display: none;
}

@media only screen and (max-width: 487px) {
  .max-mobile {
    display: block;
  }
}

.only-mobile {
  display: block;
}

@media only screen and (min-width: 768px) {
  .only-mobile {
    display: none;
  }
}

.only-tablet {
  display: none;
}

@media only screen and (min-width: 768px) {
  .only-tablet {
    display: block;
  }
}

@media only screen and (min-width: 1320px) {
  .only-tablet {
    display: none;
  }
}

.only-desktop {
  display: none;
}

@media only screen and (min-width: 1320px) {
  .only-desktop {
    display: block;
  }
}

.select2-drop:not(.select2-with-searchbox) .select2-results, .contact-wrapper .select2-container .select2-choice, .advanced-promotion-form .select2-container .select2-choice, .contact-wrapper .select2-container span, .advanced-promotion-form .select2-container span, .contact-wrapper input, .contact-wrapper textarea, .advanced-promotion-form input, .advanced-promotion-form textarea, .contact-wrapper .form-type-checkbox label, .advanced-promotion-form .form-type-checkbox label, section .small-container.regular-content p, .breadcrumb {
  font-family: open_sansregular;
}

body {
  background: #92d4f6;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

h3, h3 a, h2, h2 a {
  font-family: Futura W02 Book, Verdana, sans-serif;
  font-weight: normal;
}

.contact-wrapper label, .advanced-promotion-form label, .alert, h5, h5 a, h3, h3 a, h4, h4 a, h1 {
  font-family: gt-walsheim;
  font-weight: normal;
}

.intro, p.intro {
  font-family: Futura W02 Book, Verdana, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

h1 + p, h1 p.subtitle {
  font-family: Supernett cn W00 Bold;
  font-weight: normal;
}

.select2-drop:not(.select2-with-searchbox) .select2-results, .contact-wrapper .select2-container .select2-choice, .advanced-promotion-form .select2-container .select2-choice, .contact-wrapper .select2-container span, .advanced-promotion-form .select2-container span, .contact-wrapper input, .contact-wrapper textarea, .advanced-promotion-form input, .advanced-promotion-form textarea, .contact-wrapper .form-type-checkbox label, .advanced-promotion-form .form-type-checkbox label, section .small-container.regular-content p, .breadcrumb {
  font-family: open_sansregular;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: open_sansregular, sans-serif, Verdana;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
}

body.lang-ru, body.lang-el {
  font-family: Roboto, sans-serif, Verdana;
}

#content > h1 {
  margin: 0;
  padding: 25px 0;
}

@media only screen and (min-width: 768px) {
  #content > h1 {
    padding: 35px 0;
  }
}

@media only screen and (min-width: 1320px) {
  #content > h1 {
    padding: 45px 0;
  }
}

h1 {
  color: #2c2d87;
  text-align: center;
  text-transform: uppercase;
  z-index: 90;
  background-color: #fff;
  margin: 0 0 26px;
  font-size: 23px;
  line-height: 1.3em;
  display: block;
  position: relative;
  box-shadow: 0 4px #0000000d;
}

.lang-el h1 {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi h1 {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

@media only screen and (max-height: 700px) {
  h1 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1320px) {
  h1 {
    font-size: 36px;
  }
}

h1 a {
  color: #2c2d87;
  text-decoration: none;
  transition: color .4s;
}

h1 a:hover {
  color: #40bfef;
  text-decoration: none;
}

section > div > h1, section > h1 {
  margin: 0;
  padding: 25px 0 30px;
}

@media only screen and (max-height: 700px) {
  section > div > h1, section > h1 {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 768px) {
  section > div > h1, section > h1 {
    padding: 40px 0;
  }
}

@media only screen and (min-width: 1320px) {
  section > div > h1, section > h1 {
    padding: 43px 0;
  }
}

.front h1:before {
  content: "";
  z-index: 10;
  width: 150px;
  height: 5px;
  margin: 0 0 -20px -75px;
  display: block;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

@media only screen and (min-width: 768px) {
  .front h1:before {
    width: 200px;
    height: 6px;
    margin: 0 0 -20px -100px;
    bottom: 50px;
  }
}

h1:after {
  width: 44px;
  height: 22px;
  content: "";
  z-index: 10;
  margin-left: -22px;
  display: block;
  position: absolute;
  bottom: -22px;
  left: 50%;
}

h1 span {
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  h1 span {
    display: inline-block;
  }
}

h1 + p, h1 p.subtitle {
  color: #40bfef;
  text-align: center;
  margin: -2px auto 0;
  font-size: 17px;
  font-weight: 300;
  line-height: 1.3em;
}

.lang-ru h1 + p, .lang-el h1 + p, .lang-bg h1 + p, .lang-mk h1 + p, .lang-ru h1 p.subtitle, .lang-el h1 p.subtitle, .lang-bg h1 p.subtitle, .lang-mk h1 p.subtitle {
  letter-spacing: -.9px;
  font-family: comic_reliefregular, Verdana, sans-serif;
  font-size: 19px;
}

.lang-vi h1 + p, .lang-vi h1 p.subtitle {
  font-family: iCiel Gotham Medium, Verdana, sans-serif;
}

@media only screen and (min-width: 768px) {
  h1 + p, h1 p.subtitle {
    margin: -2px auto 12px;
    font-size: 20px;
  }
}

h1 + p a, h1 p.subtitle a {
  color: #2c2d87;
  font-weight: 500;
  transition: color .35s;
}

h1 + p a:hover, h1 p.subtitle a:hover {
  color: #40bfef;
  text-decoration: none;
}

h2, h2 a {
  color: #000;
  font-size: 20px;
  line-height: 1em;
}

.lang-vi h2, .lang-vi h2 a {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

.lang-el h3, .lang-el h3 a, .lang-el h4, .lang-el h4 a {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi h3, .lang-vi h3 a, .lang-vi h4, .lang-vi h4 a {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

h3, h3 a {
  margin: 0 0 10px;
  font-size: 20px;
  line-height: 24px;
}

.lang-vi h3, .lang-vi h3 a {
  font-family: iCiel Gotham Medium, Verdana, sans-serif;
}

h3 p {
  margin: 0;
}

.highlight h3 {
  font-family: open_sansregular, sans-serif, Verdana;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3em;
}

@media only screen and (min-width: 768px) {
  .highlight h3 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1320px) {
  .highlight h3 {
    font-size: 39px;
  }
}

.highlight h3 strong {
  font-family: gt-walsheim;
  font-weight: 700;
}

.lang-el .highlight h3 strong {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi .highlight h3 strong {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

h4, h4 a, h5, h5 a {
  font-size: 15px;
  line-height: 18px;
}

.lang-el h4, .lang-el h4 a, .lang-el h5, .lang-el h5 a {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi h4, .lang-vi h4 a, .lang-vi h5, .lang-vi h5 a {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

h5, h5 a {
  color: #2c2d87;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-size: 20px;
}

h5:hover, h5 a:hover {
  text-decoration: none;
}

p {
  margin: 0 0 20px;
  line-height: 24px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.intro, p.intro {
  font-weight: normal;
}

.regular-header {
  z-index: 90;
  background: #fff;
  padding: 30px 0;
  position: relative;
}

.node-type-advanced-promotion .regular-header {
  z-index: 3;
}

.regular-header.js-promotion-products-header {
  z-index: 5;
}

@media only screen and (min-width: 768px) {
  .regular-header {
    padding: 30px 0 27px;
    box-shadow: 0 4px #0000000d;
  }

  .section-promoties .regular-header {
    padding: 25px 0 40px;
  }

  .regular-header:after {
    width: 44px;
    height: 22px;
    content: "";
    z-index: 10;
    margin-left: -22px;
    display: block;
    position: absolute;
    bottom: -22px;
    left: 50%;
  }
}

@media only screen and (min-width: 1320px) {
  .regular-header {
    padding: 30px 0 27px;
  }
}

.regular-header .breadcrumb {
  display: none;
  top: -15px;
}

@media only screen and (min-width: 768px) {
  .regular-header .breadcrumb {
    top: -25px;
  }
}

@media only screen and (min-width: 960px) {
  .regular-header .breadcrumb {
    top: -15px;
  }
}

.regular-header h1 {
  box-shadow: none;
  margin-bottom: 0;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .regular-header h1 {
    padding-bottom: 10px;
  }
}

.regular-header h1:after {
  width: 200px;
  height: 2px;
  background: none;
  border-top: 2px dashed #0ebde7;
  margin-left: -100px;
  display: block;
  bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .regular-header h1:after {
    bottom: 5px;
  }
}

.regular-header h1 span:before {
  display: none;
}

.breadcrumb {
  color: #999;
  text-align: left;
  width: 100%;
  max-width: 1280px;
  z-index: 100;
  margin: 0;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  top: 80px;
  left: 0;
}

.lang-ru .breadcrumb, .lang-el .breadcrumb {
  font-family: Futura W02 Book, Roboto, Verdana, sans-serif;
}

@media only screen and (min-width: 960px) {
  .breadcrumb {
    top: 108px;
  }
}

@media only screen and (min-width: 1320px) {
  .breadcrumb {
    width: 1280px;
    margin-left: -640px;
    padding: 0;
    left: 50%;
  }
}

.breadcrumb a {
  color: #999;
  text-transform: lowercase;
  font-weight: 300;
  transition: color .35s;
}

.breadcrumb a:hover {
  color: #40bfef;
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.fullscreen-img img {
  max-width: none;
}

iframe {
  display: block;
}

.alert {
  background-color: #e32529;
  background-position: 12px;
  background-repeat: no-repeat;
  background-size: 4px 21px;
  padding: 10px;
  font-size: 15px;
  line-height: 18px;
  color: #fff !important;
}

.lang-el .alert {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi .alert {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

@font-face {
  font-family: gt-walsheim;
  src: url("GT-Walsheim-Pro-Ultra-Bold.076955fb.eot");
  src: url("GT-Walsheim-Pro-Ultra-Bold.076955fb.eot#iefix") format("embedded-opentype"), url("GT-Walsheim-Pro-Ultra-Bold.ad6d7182.woff") format("woff"), url("GT-Walsheim-Pro-Ultra-Bold.062298f7.ttf") format("truetype"), url("GT-Walsheim-Pro-Ultra-Bold.6725ff04.svg#gt-walsheim") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Futura LT W01 Light Condensed;
  src: url("b1b295d2-89ce-43c4-bf6c-abbc7d26437e.a9b6e3fe.eot#iefix");
  src: url("b1b295d2-89ce-43c4-bf6c-abbc7d26437e.a9b6e3fe.eot#iefix") format("eot"), url("73956fa0-5cd8-4815-bacd-229aaac08ea2.65c8dc74.woff2") format("woff2"), url("8eb4bbf6-6c6e-46a0-a9cc-dab8c61069c6.e734610b.woff") format("woff"), url("87433a1d-86ba-46a1-b229-679d9ddbe1d8.f74d5841.ttf") format("truetype"), url("22dbb88c-5e89-4b8b-9ea7-b60cc70d7ab3.45f78d9f.svg#22dbb88c-5e89-4b8b-9ea7-b60cc70d7ab3") format("svg");
}

@font-face {
  font-family: Supernett cn W00 Light;
  src: url("9e0fff8f-a2d2-492b-b88e-40c9e83b460b.782021fd.eot#iefix");
  src: url("9e0fff8f-a2d2-492b-b88e-40c9e83b460b.782021fd.eot#iefix") format("eot"), url("1e73f70d-aab5-4050-a10a-cd8b67ad0323.2b690631.woff2") format("woff2"), url("1e79fe36-7868-427b-99ab-e7f0875d06c2.5b95853a.woff") format("woff"), url("7c0705c9-d5f0-45a9-ad80-9d5150e7556b.a71c3b89.ttf") format("truetype"), url("493674a0-f76e-4e1c-8c29-72237d09dd2b.3a11b2af.svg#493674a0-f76e-4e1c-8c29-72237d09dd2b") format("svg");
}

@font-face {
  font-family: Supernett cn W00 Bold;
  src: url("21991d25-436e-40aa-aa3a-ff9eb110ac87.1e37ee6c.eot#iefix");
  src: url("21991d25-436e-40aa-aa3a-ff9eb110ac87.1e37ee6c.eot#iefix") format("eot"), url("1bae1467-99a3-4ff3-a41f-f06494b2c05e.c427973a.woff2") format("woff2"), url("ba35a7a1-7e88-4e55-8ebd-54833f2bcf70.377271b1.woff") format("woff"), url("45ea2dcb-006f-484a-b777-141001c3b7d8.38c6683e.ttf") format("truetype"), url("2fe7f19a-d396-4420-9c3d-a48fbca351c5.29a79630.svg#2fe7f19a-d396-4420-9c3d-a48fbca351c5") format("svg");
}

@font-face {
  font-family: open_sansregular;
  src: url("OpenSans-Regular-webfont.a6a34476.eot");
  src: url("OpenSans-Regular-webfont.a6a34476.eot#iefix") format("embedded-opentype"), url("OpenSans-Regular-webfont.691adf57.woff") format("woff"), url("OpenSans-Regular-webfont.8bd2b33b.ttf") format("truetype"), url("OpenSans-Regular-webfont.6737b307.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: comic_reliefregular;
  src: url("ComicRelief-webfont.125358e4.eot");
  src: url("ComicRelief-webfont.125358e4.eot#iefix") format("embedded-opentype"), url("ComicRelief-webfont.75965a4b.woff") format("woff"), url("ComicRelief-webfont.a56116a5.ttf") format("truetype"), url("ComicRelief-webfont.cbd62d16.svg#comic_reliefregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: iCiel Gotham Ultra;
  src: url("iCielGotham-Ultra.1d20b342.eot");
  src: url("iCielGotham-Ultra.1d20b342.eot#iefix") format("embedded-opentype"), url("iCielGotham-Ultra.84a93af4.woff") format("woff"), url("iCielGotham-Ultra.87992c0a.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: iCiel Gotham Medium;
  src: url("iCielGotham-Medium.fbc8f4af.eot");
  src: url("iCielGotham-Medium.fbc8f4af.eot#iefix") format("embedded-opentype"), url("iCielGotham-Medium.3fbf6590.woff") format("woff"), url("iCielGotham-Medium.bf31a782.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: iCiel Gotham Thin;
  src: url("iCielGotham-Thin.0240a722.eot");
  src: url("iCielGotham-Thin.0240a722.eot#iefix") format("embedded-opentype"), url("iCielGotham-Thin.586b321e.woff") format("woff"), url("iCielGotham-Thin.2219df04.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

* {
  -webkit-tap-highlight-color: #0000;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.5em;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, * html h1, * html h2 {
  margin-top: 0;
}

h2 {
  margin-top: 1.6875em;
  margin-bottom: .5625em;
  font-size: 1.3333em;
  line-height: 1.125em;
}

h3 {
  margin-top: 1.929em;
  margin-bottom: .643em;
  font-size: 1.1667em;
  line-height: 1.286em;
}

h2 + h3 {
  margin-top: 1.2857em;
}

h4 {
  margin-top: 1.5em;
}

h3 + h4 {
  margin-top: .5em;
}

h5 {
  margin-top: 1.5em;
  font-weight: 400;
}

h4 + h5 {
  margin-top: 0;
}

p {
  margin: 0 0 1.5em;
  font-size: 1em;
  line-height: 1.5em;
}

p.intro {
  font-size: 1.08333em;
  font-weight: 700;
  line-height: 1.3846em;
}

blockquote {
  margin: 0 2.5em 1.5em;
  font-style: oblique;
}

q {
  font-style: oblique;
}

hr {
  height: 1px;
  background: #333;
  border: 0;
  margin: 0 0 1.5em;
}

small {
  font-size: .8333em;
  line-height: 1.8em;
}

a img, :link img, :visited img {
  border: 0;
}

a {
  background: none;
  outline: 0;
}

address {
  font-style: normal;
}

figure {
  margin: 0;
}

ul, ol {
  margin: 0 0 1.5em 2.5em;
}

li ul, li ol {
  margin: 0 0 0 2.5em;
}

dl {
  margin: 0 0 1.5em;
}

dt {
  margin: 1.5em 0 0;
  font-weight: 700;
}

dd {
  margin: 0 0 0 2.5em;
}

.break {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.hide, .skip {
  display: none;
}

.center {
  text-align: center;
}

fieldset {
  border: 0;
}

form br {
  clear: left;
}

label {
  float: left;
  width: 150px;
  margin: 0 0 .9em;
}

label.inline {
  width: auto;
  margin-right: 15px;
  display: inline;
}

input, input.text, textarea, select {
  width: 300px;
  margin: 0 0 .9em;
  font-family: inherit;
  font-size: 100%;
}

textarea {
  overflow: auto;
}

label input {
  width: auto;
  height: auto;
  vertical-align: middle;
  border: 0;
  margin: 0 5px 0 0;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input.inline, select.inline {
  width: 150px;
}

input.small {
  width: 30px;
}

input.medium {
  width: 60px;
}

label, button {
  cursor: pointer;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button, input {
  line-height: normal;
}

button[disabled], input[disabled] {
  cursor: default;
}

input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.feedback-error, .feedback-warning, .feedback-confirm, .feedback-notice {
  color: #000;
  background-color: #ffbaba;
  background-position: 3%;
  background-repeat: no-repeat;
  border: 1px solid #d8000c;
  margin: 0 0 1.5em;
  padding: 1.4255em 10px 1.4255em 50px;
  list-style: none;
}

.feedback-warning {
  background-color: #feffca;
  border: 1px solid gold;
}

.feedback-confirm {
  background-color: #e7f9e8;
  border: 1px solid #3e983b;
}

.feedback-notice {
  background-color: #bde5f8;
  border: 1px solid #00529b;
}

body.countries .countrieslist ul:after {
  content: "";
  clear: both;
  display: block;
}

.container {
  max-width: 1280px;
  margin: 0 5%;
  position: relative;
}

@media only screen and (min-width: 1320px) {
  .container {
    margin: 0 auto;
  }
}

.main-wrapper {
  float: left;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .main-wrapper {
    float: none;
  }
}

.main-wrapper #content {
  overflow: hidden;
}

@media only screen and (max-width: 959px) {
  .main-wrapper #content {
    margin: 46px 0 0;
  }
}

@media only screen and (min-width: 768px) {
  .main-wrapper #content {
    margin-top: 46px;
  }

  .front .main-wrapper #content {
    margin-top: 44px;
  }
}

@media only screen and (min-width: 960px) {
  .front .main-wrapper #content, .node-type-product .main-wrapper #content {
    margin-top: 0;
  }

  .main-wrapper #content, .promotiontype-1 .main-wrapper #content, .node-type-advanced-promotion.iframe-not-transparent .main-wrapper #content {
    margin-top: 128px;
  }

  .main-wrapper #content .logged-in {
    margin-top: 165px;
  }

  .logged-in:not(.front) .main-wrapper #content {
    margin-top: 157px;
  }

  .logged-in.front .main-wrapper #content, .logged-in.promotiontype-0 .main-wrapper #content, .logged-in.promotiontype-2 .main-wrapper #content, .logged-in.promotiontype-2 .main-wrapper #content, .logged-in.node-type-advanced-promotion:not(.iframe-not-transparent) .main-wrapper #content, .logged-in.node-type-product .main-wrapper #content {
    margin-top: 0;
  }
}

section {
  z-index: 1;
  background-color: #fff;
  position: relative;
}

section#views_homepage_products-block {
  background-color: #92d4f6;
}

section#social {
  min-height: 0;
}

section.white {
  background: #fff;
}

section.sprinkles {
  background: #92d4f6;
  padding: 15px 0;
}

@media only screen and (min-width: 768px) {
  section.sprinkles {
    padding: 30px 20px 20px;
  }
}

section.triangles {
  background: #40c0f0;
  padding: 40px 0;
}

.section-terms-of-use section.triangles {
  padding: 0 40px 40px;
}

section .small-container {
  box-sizing: border-box;
  max-width: 1024px;
  background: #fff;
  border-radius: 9px;
  margin: 0 5%;
  padding: 20px 3%;
  position: relative;
  overflow: hidden;
}

section .small-container--transparent {
  background-color: #0000;
}

@media only screen and (min-width: 768px) {
  section .small-container {
    padding: 35px;
  }
}

@media only screen and (min-width: 960px) {
  section .small-container {
    margin: 0 auto;
  }

  section .small-container.regular-content {
    padding: 35px 60px;
  }
}

section .small-container.regular-content h5 {
  color: #40c0f0;
  position: relative;
}

section .small-container.regular-content p {
  color: #2c2d87;
  font-weight: 400;
}

.lang-ru section .small-container.regular-content p, .lang-pl section .small-container.regular-content p, .lang-cs section .small-container.regular-content p, .lang-sk section .small-container.regular-content p, .lang-el section .small-container.regular-content p {
  font-family: Futura W02 Book, Roboto, Verdana, sans-serif;
}

section .small-container.regular-content ul {
  list-style-type: square;
}

section .small-container.regular-content li {
  color: #98a0a8;
  font-family: open_sansregular, sans-serif, Verdana;
  font-weight: 400;
}

section .small-container p {
  max-width: 100%;
}

section .small-container a {
  color: #40bfef;
  transition: color .35s;
}

section .small-container a:hover {
  color: #2c2d87;
  text-decoration: none;
}

section footer {
  text-align: center;
  background-color: #92d4f6;
  padding: 30px 0;
}

section footer p {
  line-height: 1em;
}

section footer.highlight {
  color: #fff;
  background-color: #40bfef;
  padding: 25px 0;
  box-shadow: inset 0 5px #3333331a;
}

section footer.highlight h3 {
  text-shadow: 1px 1px 2px #00000040;
  text-transform: uppercase;
  margin-bottom: 0;
}

section footer.highlight h3 strong {
  color: #fff;
}

section footer.highlight p {
  text-shadow: 1px 1px 2px #00000040;
  text-transform: lowercase;
  margin-bottom: 0;
  font-size: 18px;
}

@media only screen and (min-width: 768px) {
  section footer.highlight p {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1320px) {
  section footer.highlight p {
    font-size: 25px;
  }
}

section footer.transparent {
  background: none;
  padding: 30px 0;
}

section footer .button.rounded {
  color: #2c2d87;
}

body .edit-title-wrapper, body .views-exposed-widgets, body .views-exposed-form .views-exposed-widget {
  margin-bottom: 0;
  padding: 0;
}

body .element-invisible {
  display: none;
}

@media (max-width: 767px) {
  #cookie-message button {
    margin-bottom: 30px;
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: 2em;
  }
}

:focus {
  outline: none;
}

.select2-container {
  width: 100% !important;
}

.select2-container .select2-choice {
  color: #2c2d87;
  height: 36px;
  text-align: left;
  background: #fff;
  border-radius: 9px;
  padding: 0 13px;
  font-family: open_sansregular, sans-serif, Verdana;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}

.lang-ru .select2-container .select2-choice, .lang-pl .select2-container .select2-choice, .lang-cs .select2-container .select2-choice, .lang-sk .select2-container .select2-choice, .lang-el .select2-container .select2-choice {
  font-family: Roboto, sans-serif, Verdana;
}

.select2-container .select2-choice div {
  background: #fff;
  border-radius: 0;
}

.select2-container .select2-choice div b {
  background-position: 0 13px;
}

.contact-wrapper .select2-container, .advanced-promotion-form .select2-container {
  height: 50px;
  background: #f3f3f3;
  border-radius: 9px;
  margin-bottom: 18px;
  overflow: hidden;
}

.contact-wrapper .select2-container span, .advanced-promotion-form .select2-container span {
  height: 45px;
  font-size: 14px;
  line-height: 45px;
}

@media only screen and (min-width: 768px) {
  .contact-wrapper .select2-container span, .advanced-promotion-form .select2-container span {
    font-size: 16px;
  }
}

.contact-wrapper .select2-container .select2-choice, .advanced-promotion-form .select2-container .select2-choice {
  color: #2c2d87;
  height: 50px;
  text-align: left;
  background: #f3f3f3;
  border-radius: 9px;
  padding: 0 13px;
  font-size: 14px;
  font-weight: 400;
  line-height: 50px;
  margin: 0 !important;
}

@media only screen and (min-width: 768px) {
  .contact-wrapper .select2-container .select2-choice, .advanced-promotion-form .select2-container .select2-choice {
    font-size: 16px;
  }
}

.select2-container svg {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
}

.select2-container svg path {
  fill: #2c2d87;
}

input, textarea {
  box-sizing: border-box;
  color: #40bfef;
  height: 50px;
  width: 100%;
  -webkit-appearance: none;
  background: #dbf3fc;
  border: none;
  border-radius: 0;
  padding: 0 10px;
  font-family: gt-walsheim;
  font-size: 18px;
  line-height: 50px;
  display: inline-block;
}

.lang-el input, .lang-el textarea {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi input, .lang-vi textarea {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #9f9f9f;
  opacity: 1;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #9f9f9f;
  opacity: 1;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #9f9f9f;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #9f9f9f;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  input, textarea {
    width: 410px;
    margin-right: 36px;
  }
}

input:-webkit-autofill, textarea:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #dbf3fc;
}

input.error, textarea.error {
  background: #f2aeae;
  border: 1px solid #e32529;
}

textarea {
  padding: 18px;
  line-height: 1.3em;
}

.messages--error {
  display: none;
}

.views-exposed-form {
  position: relative;
}

.views-exposed-form input {
  height: 45px;
  text-transform: none;
  width: 100%;
  background-image: none;
  margin: 0;
  padding: 0 18px;
  font-family: open_sansregular, sans-serif, Verdana;
  font-weight: 300;
  line-height: 45px;
}

.views-exposed-form input::-webkit-input-placeholder {
  color: #40bfef;
  opacity: 1;
}

.views-exposed-form input:-moz-placeholder {
  color: #40bfef;
  opacity: 1;
}

.views-exposed-form input::-moz-placeholder {
  color: #40bfef;
  opacity: 1;
}

.views-exposed-form input:-ms-input-placeholder {
  color: #40bfef;
  opacity: 1;
}

.lang-ru .views-exposed-form input, .lang-pl .views-exposed-form input, .lang-cs .views-exposed-form input, .lang-sk .views-exposed-form input, .lang-el .views-exposed-form input {
  font-family: Roboto, sans-serif, Verdana;
}

@media only screen and (min-width: 768px) {
  .views-exposed-form input {
    width: 240px;
  }
}

.views-exposed-form input.error {
  background: #f2aeae;
  border: 1px solid #e32529;
}

.views-exposed-form .views-submit-button {
  height: 100%;
  width: 35px;
  position: absolute;
  top: 0;
  right: 0;
}

.views-exposed-form .views-submit-button input {
  color: #dbf3fc;
  height: 100%;
  text-indent: -9999px;
  width: 100%;
  padding: 0;
}

.views-exposed-form .views-submit-button input::-webkit-input-placeholder {
  color: #dbf3fc;
  opacity: 1;
}

.views-exposed-form .views-submit-button input:-moz-placeholder {
  color: #dbf3fc;
  opacity: 1;
}

.views-exposed-form .views-submit-button input::-moz-placeholder {
  color: #dbf3fc;
  opacity: 1;
}

.views-exposed-form .views-submit-button input:-ms-input-placeholder {
  color: #dbf3fc;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  .contact-wrapper #webform-component-your-name, .contact-wrapper #webform-component-your-email, .advanced-promotion-form #webform-component-your-name, .advanced-promotion-form #webform-component-your-email {
    float: left;
    display: inline-block;
  }

  .contact-wrapper #webform-component-your-name, .advanced-promotion-form #webform-component-your-name {
    margin-left: 0;
  }
}

.contact-wrapper .webform-component, .advanced-promotion-form .webform-component {
  vertical-align: top;
}

.contact-wrapper .form-type-checkbox label, .advanced-promotion-form .form-type-checkbox label {
  color: #98a0a8;
  text-transform: none;
  margin-bottom: 9px;
  font-size: 15px;
  font-weight: 400;
}

.contact-wrapper label, .advanced-promotion-form label {
  color: #40bfef;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 3px;
  font-size: 14px;
}

.lang-el .contact-wrapper label, .lang-el .advanced-promotion-form label {
  font-family: Arial Black, Gadget, sans-serif;
}

.lang-vi .contact-wrapper label, .lang-vi .advanced-promotion-form label {
  font-family: iCiel Gotham Ultra, Verdana, sans-serif;
}

@media only screen and (min-width: 768px) {
  .contact-wrapper label, .advanced-promotion-form label {
    font-size: 16px;
  }
}

@media only screen and (min-width: 960px) {
  .contact-wrapper label, .advanced-promotion-form label {
    font-size: 18px;
  }
}

.contact-wrapper label.dropdown, .advanced-promotion-form label.dropdown {
  width: 100%;
}

.contact-wrapper label input, .advanced-promotion-form label input {
  height: 45px;
  text-transform: none;
  line-height: 45px;
}

.contact-wrapper #edit-submitted-upload-upload input, .advanced-promotion-form #edit-submitted-upload-upload input {
  margin: 0 36px 18px 0;
}

.contact-wrapper #edit-submitted-upload-upload input:first-child, .advanced-promotion-form #edit-submitted-upload-upload input:first-child {
  color: #888;
  text-transform: inherit;
  height: auto;
  padding: 15px;
  font-family: inherit;
  font-size: 12px;
  line-height: 0;
}

.contact-wrapper #edit-submitted-upload-upload .form-file input[type="file"], .advanced-promotion-form #edit-submitted-upload-upload .form-file input[type="file"] {
  cursor: pointer;
  background: red;
  border: 0;
  border-radius: 5px;
  padding: 5px 15px;
}

.contact-wrapper input, .contact-wrapper textarea, .advanced-promotion-form input, .advanced-promotion-form textarea {
  color: #2c2d87;
  resize: none;
  width: 100%;
  text-transform: uppercase;
  background: #f3f3f3;
  border: none;
  border-radius: 9px;
  margin-bottom: 18px;
  padding: 0 18px;
  font-family: gt-walsheim;
  font-size: 14px;
}

.contact-wrapper input.form-submit.ajax-processed, .contact-wrapper textarea.form-submit.ajax-processed, .advanced-promotion-form input.form-submit.ajax-processed, .advanced-promotion-form textarea.form-submit.ajax-processed {
  color: #f3f3f3;
  background: #2c2d87;
  margin: 10px 0 20px;
}

.contact-wrapper input.custom-submit-btn, .contact-wrapper textarea.custom-submit-btn, .advanced-promotion-form input.custom-submit-btn, .advanced-promotion-form textarea.custom-submit-btn {
  color: #fff;
  background: #2c2d87;
  margin: 20px 0;
  transition: all .3s;
}

.contact-wrapper input.custom-submit-btn:hover, .contact-wrapper textarea.custom-submit-btn:hover, .advanced-promotion-form input.custom-submit-btn:hover, .advanced-promotion-form textarea.custom-submit-btn:hover {
  background-color: #40bfef;
  text-decoration: none;
}

.lang-ru .contact-wrapper input, .lang-pl .contact-wrapper input, .lang-cs .contact-wrapper input, .lang-sk .contact-wrapper input, .lang-el .contact-wrapper input, .lang-ru .contact-wrapper textarea, .lang-pl .contact-wrapper textarea, .lang-cs .contact-wrapper textarea, .lang-sk .contact-wrapper textarea, .lang-el .contact-wrapper textarea, .lang-ru .advanced-promotion-form input, .lang-pl .advanced-promotion-form input, .lang-cs .advanced-promotion-form input, .lang-sk .advanced-promotion-form input, .lang-el .advanced-promotion-form input, .lang-ru .advanced-promotion-form textarea, .lang-pl .advanced-promotion-form textarea, .lang-cs .advanced-promotion-form textarea, .lang-sk .advanced-promotion-form textarea, .lang-el .advanced-promotion-form textarea {
  font-family: Futura W02 Book, Roboto, Verdana, sans-serif;
}

.lang-vi .contact-wrapper input, .lang-vi .contact-wrapper textarea, .lang-vi .advanced-promotion-form input, .lang-vi .advanced-promotion-form textarea {
  font-family: iCiel Gotham Medium, Verdana, sans-serif;
}

@media only screen and (min-width: 768px) {
  .contact-wrapper input, .contact-wrapper textarea, .advanced-promotion-form input, .advanced-promotion-form textarea {
    font-size: 16px;
  }
}

.contact-wrapper input:-webkit-autofill, .contact-wrapper textarea:-webkit-autofill, .advanced-promotion-form input:-webkit-autofill, .advanced-promotion-form textarea:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #efeeea;
}

.contact-wrapper input.error, .contact-wrapper textarea.error, .advanced-promotion-form input.error, .advanced-promotion-form textarea.error {
  background: #f2aeae;
  border: 1px solid #e32529;
}

.contact-wrapper textarea, .advanced-promotion-form textarea {
  height: 250px;
  padding-top: 12px;
}

.lang-ru .contact-wrapper .select2-container span, .lang-pl .contact-wrapper .select2-container span, .lang-cs .contact-wrapper .select2-container span, .lang-sk .contact-wrapper .select2-container span, .lang-el .contact-wrapper .select2-container span, .lang-ru .advanced-promotion-form .select2-container span, .lang-pl .advanced-promotion-form .select2-container span, .lang-cs .advanced-promotion-form .select2-container span, .lang-sk .advanced-promotion-form .select2-container span, .lang-el .advanced-promotion-form .select2-container span, .lang-ru .contact-wrapper .select2-container .select2-choice, .lang-pl .contact-wrapper .select2-container .select2-choice, .lang-cs .contact-wrapper .select2-container .select2-choice, .lang-sk .contact-wrapper .select2-container .select2-choice, .lang-el .contact-wrapper .select2-container .select2-choice, .lang-ru .advanced-promotion-form .select2-container .select2-choice, .lang-pl .advanced-promotion-form .select2-container .select2-choice, .lang-cs .advanced-promotion-form .select2-container .select2-choice, .lang-sk .advanced-promotion-form .select2-container .select2-choice, .lang-el .advanced-promotion-form .select2-container .select2-choice {
  font-family: Futura W02 Book, Roboto, Verdana, sans-serif;
}

@media only screen and (min-width: 768px) {
  .contact-wrapper .button, .contact-wrapper .form-submit, .advanced-promotion-form .button, .advanced-promotion-form .form-submit {
    display: inherit;
    width: auto;
    min-width: 180px;
    padding-right: 20px;
  }

  .contact-wrapper .button:after, .contact-wrapper .form-submit:after, .advanced-promotion-form .button:after, .advanced-promotion-form .form-submit:after {
    display: none;
  }
}

.select2-drop:not(.select2-with-searchbox) .select2-results {
  max-height: 275px;
  background: #f3f3f3;
  border-bottom: 2px solid #fff;
  border-radius: 0 0 9px 9px;
  font-size: 14px;
  font-weight: normal;
}

.lang-ru .select2-drop:not(.select2-with-searchbox) .select2-results, .lang-pl .select2-drop:not(.select2-with-searchbox) .select2-results, .lang-cs .select2-drop:not(.select2-with-searchbox) .select2-results, .lang-sk .select2-drop:not(.select2-with-searchbox) .select2-results, .lang-el .select2-drop:not(.select2-with-searchbox) .select2-results {
  font-family: Futura W02 Book, Roboto, Verdana, sans-serif;
}

@media only screen and (min-width: 768px) {
  .select2-drop:not(.select2-with-searchbox) .select2-results {
    font-size: 16px;
  }
}

.select2-drop:not(.select2-with-searchbox) .select2-results .select2-highlighted {
  color: #2c2d87;
  background: #cdcdcd;
}

.select2-drop:not(.select2-with-searchbox) .select2-results .select2-result {
  height: auto;
  padding: 5px 0;
  line-height: 130%;
}

.webform-container-inline.webform-datepicker div.form-item.form-type-select {
  display: none;
}

body.countries h1 {
  box-shadow: none;
}

body.countries h1:after, body.countries h1 span:before {
  display: none;
}

body.countries header {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  body.countries header {
    margin-top: 50px;
  }
}

body.countries header h1 {
  width: 255px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  body.countries header h1 {
    margin-bottom: 25px;
  }
}

body.countries header h1:after, body.countries header h1:before {
  display: none;
}

body.countries #countries-search {
  text-align: center;
}

body.countries .select2-container {
  max-width: 410px;
  background: #d4f4ff;
}

@media only screen and (min-width: 768px) {
  body.countries .select2-container {
    margin-right: 12px;
  }
}

body.countries .select2-container .select2-default, body.countries .select2-container .select2-choice {
  box-sizing: border-box;
  height: 50px;
  text-transform: uppercase;
  background: #d4f4ff url("search-icon.88e786e0.png") 96% 15px no-repeat;
  font-family: gt-walsheim;
  font-size: 18px;
  line-height: 50px;
  display: block;
}

body.countries .select2-container .select2-default::-webkit-input-placeholder, body.countries .select2-container .select2-choice::-webkit-input-placeholder {
  color: #c0c0c2;
  opacity: 1;
}

body.countries .select2-container .select2-default:-moz-placeholder, body.countries .select2-container .select2-choice:-moz-placeholder {
  color: #c0c0c2;
  opacity: 1;
}

body.countries .select2-container .select2-default::-moz-placeholder, body.countries .select2-container .select2-choice::-moz-placeholder {
  color: #c0c0c2;
  opacity: 1;
}

body.countries .select2-container .select2-default:-ms-input-placeholder, body.countries .select2-container .select2-choice:-ms-input-placeholder {
  color: #c0c0c2;
  opacity: 1;
}

.lang-el body.countries .select2-container .select2-default, .lang-el body.countries .select2-container .select2-choice {
  font-family: Arial Black, Gadget, sans-serif;
}

body.countries .select2-container .select2-default span, body.countries .select2-container .select2-choice span {
  color: #c0c0c2;
}

body.countries .select2-results {
  color: #c0c0c2;
  min-height: 50px;
  max-height: 175px;
  background-color: #d4f4ff;
  margin-top: 0;
  padding-top: 5px;
  overflow-y: scroll;
}

@media only screen and (min-width: 768px) {
  body.countries .select2-results {
    max-height: 250px;
  }
}

body.countries .select2-results .select2-highlighted {
  color: #fff;
}

body.countries .select2-results .select2-no-results {
  background-color: #d4f4ff;
  padding-left: 13px;
}

body.countries .select2-search {
  padding: 0;
}

body.countries .select2-search input {
  color: #c0c0c2;
  height: 35px;
  background: #d4f4ff url("search-icon.88e786e0.png") 96% 15px / 16px 16px no-repeat;
  border-bottom: 1px solid #40bfef;
  padding: 5px 13px;
  font-family: gt-walsheim;
  line-height: 35px;
}

.lang-el body.countries .select2-search input {
  font-family: Arial Black, Gadget, sans-serif;
}

body.countries .search-holder {
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) {
  body.countries .search-holder {
    margin-bottom: 50px;
  }
}

body.countries .search-holder input {
  background-color: #d4f4ff;
}

body.countries .search-holder input::-webkit-input-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

body.countries .search-holder input:-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

body.countries .search-holder input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

body.countries .search-holder input:-ms-input-placeholder {
  color: #bfbfbf;
  opacity: 1;
}

@media only screen and (min-width: 768px) {
  body.countries .search-holder input {
    margin-right: 12px;
  }
}

body.countries .search-holder .button {
  margin: 0 auto;
  display: block;
}

@media only screen and (min-width: 768px) {
  body.countries .search-holder .button {
    height: 52px;
    margin: -3px 0 0;
    line-height: 26px;
    display: inline-block;
  }
}

body.countries .select2-drop {
  margin-top: -50px;
}

body.countries #countries-list {
  color: #2c2d87;
  text-transform: uppercase;
  font-family: gt-walsheim;
  font-size: 16px;
  line-height: 20px;
}

.lang-el body.countries #countries-list {
  font-family: Arial Black, Gadget, sans-serif;
}

body.countries #countries-list .container {
  overflow: hidden;
}

body.countries #countries-list ul {
  width: 100%;
  margin: 0;
  list-style: none;
}

@media only screen and (min-width: 768px) {
  body.countries #countries-list ul {
    width: 50%;
    float: left;
  }
}

@media only screen and (min-width: 960px) {
  body.countries #countries-list ul {
    width: 33.33%;
  }
}

body.countries #countries-list li {
  box-sizing: border-box;
  min-height: 55px;
  padding: 10px;
  transition: background-color .25s;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  body.countries #countries-list li {
    height: 55px;
    padding: 10px 22px;
  }
}

@media only screen and (min-width: 960px) {
  body.countries #countries-list li {
    padding: 10px 12px;
  }
}

@media only screen and (min-width: 1320px) {
  body.countries #countries-list li {
    padding: 10px 40px 10px 22px;
  }
}

body.countries #countries-list li:hover {
  color: #fff;
  background-color: #40bfef;
}

body.countries #countries-list li:hover:after {
  animation: 2.5s ease-in-out infinite floatingX;
}

body.countries #countries-list li:hover > a {
  color: #fff;
  text-decoration: none;
}

body.countries #countries-list li:hover .country-social {
  height: 35px;
  display: table;
}

@media only screen and (min-width: 1320px) {
  body.countries #countries-list li:after {
    content: "";
    height: 0;
    vertical-align: middle;
    width: 0;
    border-top: 10px solid #0000;
    border-bottom: 10px solid #0000;
    border-left: 10px solid #fff;
    margin: -10px 0 0 18px;
    position: absolute;
    top: 50%;
    right: 14px;
  }
}

body.countries #countries-list li > a {
  color: #2c2d87;
  float: left;
  height: 35px;
  width: 90%;
  display: table;
}

@media only screen and (min-width: 768px) {
  body.countries #countries-list li > a {
    width: 120px;
  }
}

@media only screen and (min-width: 1320px) {
  body.countries #countries-list li > a {
    width: 160px;
  }
}

body.countries #countries-list li > a span {
  vertical-align: middle;
  display: table-cell;
}

body.countries #countries-list .country-social {
  float: left;
  display: none;
}

@media only screen and (min-width: 768px) {
  body.countries #countries-list .country-social {
    float: right;
  }
}

body.countries #countries-list .country-social a {
  height: 24px;
  width: 26px;
  background: url("social-countries.26391757.png") 0 0 no-repeat;
  margin: 4px 8px 0 0;
  display: inline-block;
}

@media only screen and (min-width: 768px) {
  body.countries #countries-list .country-social a {
    margin: 4px 0 0 4px;
  }
}

@media only screen and (min-width: 1320px) {
  body.countries #countries-list .country-social a {
    margin: 4px 0 0 8px;
  }
}

body.countries #countries-list .country-social a:hover {
  background: url("social-countries.26391757.png") 0 0 no-repeat;
}

body.countries #countries-list .country-social a.facebook {
  background-position: 10px -96px;
}

body.countries #countries-list .country-social a.twitter {
  background-position: -39px -71px;
}

body.countries #countries-list .country-social a.pinterest {
  background-position: -82px -47px;
}

body.countries #countries-list .country-social a.youtube {
  background-position: -126px -24px;
}

body.countries #countries-list .country-social a.instagram {
  background-position: -173px 2px;
}

body.countries #countries-list footer {
  clear: both;
  background-color: #fff;
  margin: 50px auto 30px;
}

body.countries #countries-list h3 {
  margin-bottom: 15px;
}

@media only screen and (min-width: 960px) {
  body.countries .countrieslist {
    width: 17%;
    float: left;
    margin-top: 30px;
    margin-left: 1%;
  }

  body.countries .countrieslist .button {
    display: none !important;
  }
}

@media only screen and (min-width: 1320px) {
  body.countries .countrieslist {
    width: 24%;
  }
}

body.countries .countrieslist ul {
  color: #2c2d87;
  text-transform: uppercase;
  margin: 0 0 20px;
  font-family: gt-walsheim;
  font-size: 16px;
  line-height: 20px;
  list-style: none;
}

.lang-el body.countries .countrieslist ul {
  font-family: Arial Black, Gadget, sans-serif;
}

body.countries .countrieslist li {
  width: 50%;
  float: left;
}

@media only screen and (min-width: 768px) {
  body.countries .countrieslist li {
    width: 33%;
  }
}

body.countries .countrieslist li a {
  color: #2c2d87;
  padding-bottom: 20px;
  display: inline-block;
}

body.countries .countrieslist li a:hover {
  color: #40bfef;
  text-decoration: none;
}

body.countries .continent li, body.countries .continents li {
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  body.countries .continent li, body.countries .continents li {
    width: 50%;
    float: left;
  }
}

@media only screen and (min-width: 960px) {
  body.countries .continent li, body.countries .continents li {
    float: none;
    text-align: left;
    width: 100%;
  }
}

@media only screen and (min-width: 1320px) {
  body.countries .continent li {
    float: left;
    width: 50%;
  }
}

body.countries .mapwrap {
  max-width: 1072px;
  margin: 0 auto;
  padding-bottom: 50%;
  display: none;
  position: relative;
}

@media only screen and (min-width: 768px) {
  body.countries .mapwrap {
    padding-bottom: 55%;
    display: block;
  }
}

@media only screen and (min-width: 960px) {
  body.countries .mapwrap {
    width: 82%;
    float: left;
    padding-bottom: 50%;
  }
}

@media only screen and (min-width: 1320px) {
  body.countries .mapwrap {
    width: 75%;
    padding-bottom: 46%;
  }
}

body.countries .mapwrap.hidden .map-overlay {
  opacity: 0;
}

body.countries .mapwrap img {
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  position: absolute;
  top: 0;
  left: 0;
}

body.countries .mapwrap .map-overlay.visible {
  opacity: 1;
}

body.countries .mapwrap area {
  cursor: pointer;
  display: block;
}

body.countries section.continent-na .map-overlay {
  opacity: 0;
}

body.countries section.continent-na #worldmap-overlay-na {
  opacity: 1;
}

body.countries section.continent-sa .map-overlay {
  opacity: 0;
}

body.countries section.continent-sa #worldmap-overlay-sa {
  opacity: 1;
}

body.countries section.continent-af .map-overlay {
  opacity: 0;
}

body.countries section.continent-af #worldmap-overlay-af {
  opacity: 1;
}

body.countries section.continent-eu .map-overlay {
  opacity: 0;
}

body.countries section.continent-eu #worldmap-overlay-eu {
  opacity: 1;
}

body.countries section.continent-as .map-overlay {
  opacity: 0;
}

body.countries section.continent-as #worldmap-overlay-as {
  opacity: 1;
}

body.countries section.continent-au .map-overlay {
  opacity: 0;
}

body.countries section.continent-au #worldmap-overlay-au {
  opacity: 1;
}

body.countries .social-popup {
  display: none !important;
}

.countrylisted {
  color: #2c2d87;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  font-family: gt-walsheim;
  font-size: 16px;
  line-height: 20px;
}

.countrylisted .button {
  margin-top: 20px;
}

.lang-el .countrylisted {
  font-family: Arial Black, Gadget, sans-serif;
}

@font-face {
  font-family: gt-walsheim;
  src: url("GT-Walsheim-Pro-Ultra-Bold.076955fb.eot");
  src: url("GT-Walsheim-Pro-Ultra-Bold.076955fb.eot#iefix") format("embedded-opentype"), url("GT-Walsheim-Pro-Ultra-Bold.ad6d7182.woff") format("woff"), url("GT-Walsheim-Pro-Ultra-Bold.062298f7.ttf") format("truetype"), url("GT-Walsheim-Pro-Ultra-Bold.6725ff04.svg#gt-walsheim") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=app.css.map */
