//	===========================
//	Default variables
//	===========================

//	Media queries
$mobileLandscape:		"only screen and (min-width: 480px)"; 
$maxMobileLandscape:	"only screen and (max-width: 767px)"; //	Max!
$noTablet:				"only screen and (max-width: 487px)"; //	Max!
$tablet:				"only screen and (min-width: 768px)";
$maxTablet:				"only screen and (max-width: 960px) and (min-width: 768px)";
$maxTabletPortrait:     "only screen and (max-width: 959px)"; //	Max!
$smallDesktop:			"only screen and (min-width: 960px) and (max-height:825px)";
$mediumDesktop:			"only screen and (min-width: 960px)";
//$desktop:				"only screen and (min-width: 1300px)"; // SiteWith = 1280
$desktop:				"only screen and (min-width: 1320px)"; // SiteWith = 1280
$largedesktop:			"only screen and (min-width: 1500px)"; 

$smallheight:			"only screen and (max-height: 700px)";
$smallheightsmallwidth:	"only screen and (max-height: 700px) and (max-width: 960px)";

// Social colors
$socialButtons:			'facebook' #233588 9px 4px 8px 3px,
						'twitter' #08cbfe 9px -35px 8px -36px,
						'pinterest' #ff4040 9px -75px 7px -77px,
						'youtube' #d7002c 9px -116px 7px -117px,
						'instagram' #82dd3a 9px -156px 7px -158px,
						'whatsapp' #64d448 7px -196px 7px -196px,
						'vk' #54789f 7px -275px 7px -275px;  

// Fonts
$baseFont:				'open_sansregular', sans-serif, Verdana; //300, 400, 500, 900
$brandFont:				'Futura W02 Book', Verdana, Sans-Serif;
$brandFontBold:			'gt-walsheim'; // New font

// Fonts
$baseFontRu:				'Roboto', sans-serif, Verdana; //300, 400, 500, 900
$brandFontRu:				'Futura W02 Book', 'Roboto', Verdana, Sans-Serif;
$brandFontBoldRu:			'Futura W02 Bold', 'Futura W02 Book', Verdana, Sans-Serif;

$supernettAlternative:		'comic_reliefregular', Verdana, Sans-Serif;

$supernett:					"Supernett cn W00 Light", Verdana, Sans-Serif;

// Fonts Vietnam
$brandFontVn:				'iCiel Gotham Medium', Verdana, Sans-Serif;
$brandFontBoldVn:			'iCiel Gotham Ultra', Verdana, Sans-Serif;

$supernettAlternativeVn:	'iCiel Gotham Thin', Verdana, Sans-Serif;

// Site specific
$siteWidth:				1280px;
$gutter:				10px;
$arrowOffset: 			28px;

//	===========================
//	Theme variables
//	===========================

$themeBase:				'mentos_base';
$themeName:				'mentos_classic' !default;

$location: 'sites/all/themes';

// Urls
$baseURL:				'/#{$location}/#{$themeBase}/images'; // Use always mentos_base image
$imageURL:				'/#{$location}/#{$themeName}/images'; // Theme specific images
$bgURL:					'#{$imageURL}/backgrounds';
$spriteURL:				'#{$imageURL}/sprites';
$baseSpriteURL:			'#{$baseURL}/sprites';
$patternURL:			'#{$imageURL}/patterns';
$basePatternURL:		'#{$baseURL}/patterns';
$baseFontURL:			'/#{$location}/#{$themeBase}/fonts';

// Colors
$baseColor:				#999999; // Grijze tekstkleur
$baseColorLight:		#a7a7a7; // LichtGrijze tekstkleur
//$headerColor:			#e6faff; // Lichtblauwe achtergrondkleur header
$baseColorMentos:		#23358b; 

// Themes
$themeColors				: (#9f9f9f, #23358b, #d9d9d9, #efefef); // titel kleur links, titelkleur rechts, text-shadow, faq bg
$kaleidoscope				: (#fff, nth($themeColors, 2), 0, 0, nth($themeColors, 2), nth($themeColors, 2), nth($themeColors, 2)); // background-color 1, font-color 2, image play 3, image photo 4, article title 5, article subtitle 6, article hover background 7
$historyBg					:#EF86A9;
$historyLocation		:#945FA4;
$historyPointer			:#fed76b;

//Kleur variableen voor de campaign header labels
$colorLabelLeft:	nth($themeColors, 2);
$colorLabelRight:	nth($themeColors, 1);




//	===========================
//	Project helpers
//	===========================

//	Alignment
.alignleft {	text-align: left; }
.aligncenter {	text-align: center; }
.alignright {	text-align: right; }

//	Images
img { height: auto; max-width: 100%; }

//	No tablet
.max-mobile {
	@media #{$noTablet} {
		display: block;
	}
	display: none;

}
//	Mobile
.only-mobile {
	display: block;

	@media #{$tablet} {	display: none; }
}

//	Tablet
.only-tablet {
	display: none;

	@media #{$tablet} {	display: block; }
	@media #{$desktop} {	display: none; }
}

//	Desktop
.only-desktop {
	display: none;

	@media #{$desktop} {	display: block; }
}

// After/before with background (check: http://i.imgur.com/SQf8gde.png)
%bg-arrow {
	&:before,
	&:after {
		background-color: transparent;
		border-bottom: 10px solid #fff;
		border-left: 10px solid #fff;
		border-right: 10px solid transparent;
		bottom: 0;
		content: "";
		display: block;
		height: 10px;
		left: 0;
		position: absolute;
		width: 50%;
		z-index: 1;
	}
	
	&:after {
		border-bottom: 10px solid #fff;
		border-right: 10px solid #fff;
		border-left: 10px solid transparent;
		left: 50%;
	}
}

%opensans{
	font-family: 'open_sansregular';
}

//	===========================
//	Themes Mentos Icecrush
//	===========================
$themeColors	: (#40bfef, #2c2d87, #d9d9d9, #aeecff);
// titel kleur links, titelkleur rechts, text-shadow, faq bg
$kaleidoscope	: (nth($themeColors, 1), #fff, 45px -143px, -142px 37px, nth($themeColors, 1), #9e9e9e, nth($themeColors, 1));
// background-color 1, font-color 2, image play 3, image photo 4, article title 5, article subtitle 6, article hover background 7
$footerBgImage	: '#{$patternURL}/cubes-1.png';
$faqBgImage		: '#{$patternURL}/sprinkles-1.png';

//Kleur variabelen voor de campaign header labels
$colorLabelLeft	: nth($themeColors, 2);
$colorLabelRight: nth($themeColors, 1);

// Button wrapper productoverview
$wrapperDotImage: '#{$patternURL}/dots-1.png';
$wrapperDotColor: #00CFFE;

