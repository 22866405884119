//	==============================
//	Layout
//	==============================
.container {
	margin: 0 5%;
	max-width: $siteWidth;
	position: relative;
	@media #{$desktop} { margin: 0 auto; }
}

.main-wrapper {
	float: left;
	width: 100%;

	@media #{$mediumDesktop} { float: none; }

	#content {
		overflow: hidden;

		@media #{$maxTabletPortrait} {
			margin: 46px 0 0 0;
		}
        @media #{$tablet} {
			.front &{
				margin-top: 44px;
			}
			margin-top: 46px;
        }
		@media #{$mediumDesktop} {
			//margin-top: 74px;

			//Exception homepage + product detailpagina
			.front &,
			.node-type-product & { margin-top: 0; }
		}

		@media #{$mediumDesktop} {
			margin-top: 128px;

			.promotiontype-1 &, .node-type-advanced-promotion.iframe-not-transparent & {
				margin-top: 128px;
			}

			.logged-in{
				margin-top: 165px;
			}

			.logged-in:not(.front) & {
				margin-top: 157px;
			}
			.logged-in.front &,
			.logged-in.promotiontype-0 &,
            .logged-in.promotiontype-2 &,
			.logged-in.promotiontype-2 &,
            .logged-in.node-type-advanced-promotion:not(.iframe-not-transparent) &,
			.logged-in.node-type-product & {
				margin-top: 0;
			}
		}
	}
}

//	============================================
//	Content sections
//	============================================

section {
	background-color: #fff;
	//Fix voor background color
	position: relative;
	z-index: 1;

	// #products
	&#views_homepage_products-block {
		background-color: #92d4f6;
		//background: url('#{$baseURL}/backgrounds/light-sprinkles.png') repeat center center nth($themeColors, 4);
	}

	&#social {
		min-height: 0;
	}

	&.white { background: #fff; }

	&.sprinkles {
		background: #92d4f6;
		padding: 15px 0;

		@media #{$tablet} {padding: 30px 20px 20px;}
	}

	// Onderstaande is gekopieerd naar de user_login
	&.triangles {
		background: #40c0f0;
		padding: 40px 0;

		.section-terms-of-use &{
			padding: 0 40px 40px;
		}
	}

	// Onderstaande is ook gekopieerd naar de user_login
	.small-container {
		background: #fff;
		box-sizing: border-box;
		max-width: 1024px;
		overflow: hidden;
		position: relative;
		margin: 0 5%;
		padding: 20px 3%;
		border-radius: 9px;

		&--transparent {
			background-color: transparent;
		}

		@media #{$tablet} { padding: 35px; }
		@media #{$mediumDesktop} { margin: 0 auto; }

		&.regular-content {
			@media #{$mediumDesktop} { padding: 35px 60px; }

			h5 {
				position: relative;
				color: #40c0f0;
/*				&:before {
					border-style: solid;
					border-top-width: 9px;
					border-bottom-width: 9px;
					border-#{$direction}-width: 10px;
					border-color: transparent;
					border-#{$direction}-color: nth($themeColors, 1);
					content: "";
					height: 0;
					#{$direction}: -28px;
					top: 0;
					position: absolute;
					width: 0;
				}*/
			}

			p {
				@extend %opensans;
				color: nth($themeColors, 2);
				font-weight: 400;

				.lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
					font-family: $brandFontRu;
				}
			}

			ul { list-style-type: square; }

			li {
				color: #98a0a8;
				font-family: $baseFont;
				font-weight: 400;
			}
		}

		p { max-width: 100%;}

		a {
			color: nth($themeColors, 1);
			transition: color 350ms ease;
			&:hover { text-decoration: none; color: nth($themeColors, 2); }
		}
	}


	footer {
		background-color: #92d4f6;
		padding: 30px 0;
		text-align: center;

		p{
			line-height: 1em;
		}

		&.highlight {
			background-color: nth($themeColors, 1);
			box-shadow: inset 0 5px 0 0 rgba(51,51,51,0.1);
			color: #fff;
			padding: 25px 0;

			h3 {
				margin-bottom: 0;
				text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
				text-transform: uppercase;
				strong { color: #fff; }

				// &:nth-child(2) {}
			}
			p {
				font-size: 18px;
				//font-style: italic;
				text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
				text-transform: lowercase;
				margin-bottom: 0;

				@media #{$tablet}{ font-size: 22px;}
				@media #{$desktop}{ font-size: 25px;}
			}
		}

		&.transparent {
			background: none;
			padding: 30px 0;
		}

		.button.rounded { color: nth($themeColors, 2); }
	}
}

// Undo Drupal styles
body {
	.edit-title-wrapper,
	.views-exposed-widgets,
	.views-exposed-form .views-exposed-widget {
		margin-bottom: 0;
		padding: 0;
	}
	.element-invisible { display: none;}

}

// cookie settings update styles
#cookie-message {
	button {
		@media (max-width: 767px) {
			margin-bottom: 30px;
			line-height: 2em;
			padding-top: 13px;
			padding-bottom: 13px;
		}
	}
}