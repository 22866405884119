//	==============================
//	Forms 
//	==============================
*:focus { outline: none; }

%grey-input {
	background: #efeeea;
	border: none;
	color: nth($themeColors, 2);
	padding: 0 18px;
	resize: none;
	width: 100%;
	margin-bottom: 18px;
	margin-#{$oppositedirection}: 0;

	// Autocorrect in chrome fix
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px #efeeea inset;
	}

	// Error
	&.error {
		border: 1px solid #e32529;
		background: #f2aeae;
	}
}

%default-select {
	width: 100% !important;


	.select2-choice {
		background: #fff;
        border-radius: 9px;
		color: nth($themeColors, 2);
		font-size: 18px;
		font-family: $baseFont;
		font-weight: 400;
		height: 36px;
		line-height: 36px;
		padding: 0 13px;
		text-align: $direction;

		.lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
			font-family: $baseFontRu;
		}

		div {
			background: #fff;
			border-radius: 0;

			b { background-position: 0 13px; }
		}
	}
}

%default-select-dropdown {
    // alleen voor select2 dropdowns, niet voor de search inputs op product- en faq pagina

    height: 50px;
    background: #f3f3f3;
    border-radius: 9px;
    overflow: hidden;
    margin-bottom: 18px;

    span {
        font-size: 14px;
        height: 45px;
        line-height: 45px;

        @media #{$tablet} { font-size: 16px; }
    }

    .select2-choice {
        background: #f3f3f3;
        color: nth($themeColors, 2);
        font-size: 14px;
        font-weight: 400;
        border-radius: 9px;
        height: 50px;
        line-height: 50px;
        padding: 0 13px;
        text-align: left;
        margin: 0 !important; // Javascript plugin troep

        @media #{$tablet} { font-size: 16px; }
    }

}

%default-select-arrows {

	position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);

    path { fill: nth($themeColors, 2); }

	// .dropdown & { top: 18px; }
}

input,
textarea {
	box-sizing: border-box;
	display: inline-block;
	background: lighten(nth($themeColors, 1), 33%);
	border: none;
	color: nth($themeColors, 1);
	font-family: $brandFontBold;
	font-size: 18px;
	height: 50px;
	line-height: 50px;
	padding: 0 10px;
	//text-transform: uppercase;
	width: 100%;
	-webkit-appearance: none;
    border-radius: 0;

	.lang-el &{
		font-family: "Arial Black", Gadget, sans-serif;
	}

	.lang-vi & {
		font-family: $brandFontBoldVn;
	}

	@include placeholder($color: #9f9f9f);

	@media #{$tablet} {
		width: 410px;
		margin-#{$oppositedirection}: 36px;
	}


	// Autocorrect in chrome fix
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px lighten(nth($themeColors, 1), 33%) inset;
	}

	// Error
	&.error {
		border: 1px solid #e32529;
		background: #f2aeae;
	}
}

textarea {
	line-height: 1.3em;
	padding: 18px;
}

.messages--error { display: none;}

// Form productoverview
.views-exposed-form {
	position: relative;

	input {
		@include placeholder($color: nth($themeColors, 1));
		font-family: $baseFont;
		font-weight: 300;
		height: 45px;
		line-height: 45px;
		margin: 0;
		padding: 0 18px;
		text-transform: none;
		width: 100%;
		background-image: none;

		.lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
			font-family: $baseFontRu;
		}

		@media #{$tablet} {width: 240px;}

		// Error
		&.error {
			border: 1px solid #e32529;
			background: #f2aeae;
		}
	}

	.views-submit-button {
		height: 100%;
		position: absolute;
		#{$oppositedirection}: 0;
		top: 0;
		width: 35px;

		input {
			color: lighten(nth($themeColors, 1), 33%);
			height: 100%;
			text-indent: -9999px;
			padding: 0;
			width: 100%;
			@include placeholder($color:lighten(nth($themeColors, 1), 33%));
		}
	}
}

// Contactformulier 
.contact-wrapper, .advanced-promotion-form{

	#webform-component-your-name,
	#webform-component-your-email {
		@media #{$tablet} {
			float: $direction;
			display: inline-block;
			// margin-#{$direction}: 2%;
			// width: 49%;
		}
	}

	#webform-component-your-name {
		@media #{$tablet} { margin-#{$direction}: 0;}
	}
	.webform-component { vertical-align: top; }

	.form-type-checkbox label {
		@extend %opensans;
		color: #98a0a8;
		font-weight: 400;
		font-size: 15px;
		margin-bottom: 9px;
		text-transform: none;
	}

	label {
		@extend %brandFontBold;
		color: nth($themeColors, 1);
		font-size: 14px;
		text-transform: uppercase;
		width: 100%;
		margin-bottom: 3px;
		.lang-el & {
			font-family: "Arial Black", Gadget, sans-serif;
		}

		.lang-vi & {
			font-family: $brandFontBoldVn;
		}

		@media #{$tablet} { font-size: 16px; }
		@media #{$mediumDesktop} { font-size: 18px; }

		&.dropdown { width: 100%;}

		input {
			height: 45px;
			line-height: 45px;
			text-transform: none;
		}
	}
	#edit-submitted-upload-upload input {
		margin: 0 36px 18px 0;

		&:first-child {
			padding: 15px;
            line-height: 0;
			font-size: 12px;
			font-family: inherit;
			color: #888;
			text-transform: inherit;
			height: auto;
		}
	}

	#edit-submitted-upload-upload .form-file input[type=file] {
		padding:5px 15px;
		background: red;
		border:0 none;
		cursor:pointer;
		-webkit-border-radius: 5px;
		border-radius: 5px;
	}

	input, textarea {
		//background: lighten(nth($themeColors, 1), 33%);
		@extend %opensans;
		background: #f3f3f3;
		border: none;
		color: nth($themeColors, 2);
		padding: 0 18px;
		resize: none;
		width: 100%;
		margin-bottom: 18px;
		border-radius: 9px;
		font-size: 14px;
		text-transform: uppercase;
		font-family: $brandFontBold;

		&.form-submit.ajax-processed {
			color: #f3f3f3;
			background: nth($themeColors, 2);
			margin: 10px 0 20px;
		}

		&.custom-submit-btn {
			background: #2c2d87;
			color: #fff;
            transition: all 0.3s;
			margin: 20px 0;

			&:hover {
				background-color: #40bfef;
				text-decoration: none;
			}
		}

		.lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
			font-family: $brandFontRu;
		}

		.lang-vi & {
			font-family: $brandFontVn;
		}

		@media #{$tablet} { font-size: 16px; }

		// Autocorrect in chrome fix
		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px #efeeea inset;
		}

		// Error
		&.error {
			border: 1px solid #e32529;
			background: #f2aeae;
		}
	}

	textarea { padding-top: 12px; height: 250px; }
	// #webform-component-subject, #edit-field-subject { margin-bottom: 18px; }

	.select2-container {
        @extend %default-select-dropdown;

		span {
			@extend %opensans;

			.lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
				font-family: $brandFontRu;
			}
		}

		.select2-choice {
			@extend %opensans;

			.lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
				font-family: $brandFontRu;
			}
		}
	}

	//.select2-container .select2-choice { height: 45px; }


	.button, .form-submit {
		@media #{$tablet} {
			display: inherit;
			//margin: 0 auto;
			width: auto;
			min-width: 180px;
			padding-right: 20px;

			&:after { display: none; }
		}
	}
}

.select2-drop:not(.select2-with-searchbox) {
    .select2-results {
        @extend %opensans;
        font-weight: normal;
        //background: lighten(nth($themeColors, 1), 33%);
        background: #f3f3f3;
        border-bottom: 2px solid #fff;
        border-radius: 0 0 9px 9px;
        font-size: 14px;
        max-height: 275px;

        .lang-ru &, .lang-pl &, .lang-cs &, .lang-sk &, .lang-el & {
            font-family: $brandFontRu;
        }

        @media #{$tablet} { font-size: 16px; }

        .select2-highlighted {
            background: #cdcdcd;
            color: nth($themeColors, 2);
        }

        .select2-result {
            height: auto;
            padding: 5px 0;
            line-height: 130%;
        }
    }
}


// Select2 - social pagina

.select2-container {
	@extend %default-select;
    svg {
    	@extend %default-select-arrows;
    }
}


// Default date of birth replaced
.webform-container-inline.webform-datepicker  div.form-item.form-type-select {
    display: none;
}