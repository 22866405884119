
//	===============
//	Placeholders
//	===============

// Clearfix
%cf {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

//	Text overflow on (inline-)block elements
%text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

//	===============
//	Text mixins
//	===============


//	Hyphenation
@mixin hyphens($mode: auto) {
	word-wrap: break-all;
	-webkit-hyphens: $mode;
	-moz-hyphens: $mode;
	-ms-hyphens: $mode;
	-o-hyphens: $mode;
	hyphens: $mode;
}

//	Placeholder (HTML5 Forms)
@mixin placeholder($color: #999) {
	&::-webkit-input-placeholder { color: $color; opacity: 1; }
	&:-moz-placeholder { color: $color; opacity: 1; } // Firefox 18-
	&::-moz-placeholder { color: $color; opacity: 1; } // Firefox 19+
	&:-ms-input-placeholder { color: $color; opacity: 1; }
}

//	Font size (REM support)
@mixin font-size($sizeValue) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}

//	===============
//	Retina/ amoled screens
//	===============
@mixin retina-image($file, $type, $repeat, $width, $height) {
	background-image: url($file + '.' + $type);
	background-repeat: $repeat;
 
	@media only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (min--moz-device-pixel-ratio: 2),
		only screen and (-o-min-device-pixel-ratio: 2/1),
		only screen and (min-device-pixel-ratio: 2),
		only screen and (min-resolution: 192dpi),
		only screen and (min-resolution: 2dppx) {
			background-size: $width $height;
			background-image: url($file + '2x.' + $type);
	}
}
