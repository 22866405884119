//	===========================
//	Countries Landing page
//	===========================

body.countries {
	
	h1 {
		box-shadow: none;
		
		&:after, span:before { display: none; }
	}
	
	header {
		margin-top: 20px; 
		
		@media #{$tablet} { margin-top: 50px; }
		
		h1 {
			width: 255px;
			margin: 0 auto;
			
			@media #{$tablet} { margin-bottom: 25px; }
			&:after, &:before { display: none; }
		}
	}
	
	#countries-search { text-align: center; }
	
	// Prevent bug met scrollen en op verkeerde item klikken
	.select2-container { 
		max-width: 410px;
		background: #d4f4ff;
		@media #{$tablet} { margin-right: 12px; }
		
		.select2-default,
		.select2-choice { 
			@if $direction == "left" {
				background: url("../images/icons/search-icon.png") 96% 15px no-repeat #d4f4ff;
			} @else {
				background: url("../images/icons/search-icon.png") 4% 15px no-repeat #d4f4ff;
			}
			box-sizing: border-box;
			display: block;
			font-family: $brandFontBold;
			font-size: 18px;
			height: 50px;
			line-height: 50px;
			text-transform: uppercase;
			@include placeholder($color: #c0c0c2);

			.lang-el &{
				font-family: "Arial Black", Gadget, sans-serif;
			}

			span { color: #c0c0c2; }
		}
		
	}
	.select2-results {
		margin-top: 0;
		color: #c0c0c2;
		background-color: #d4f4ff;
		min-height: 50px;
		max-height: 175px; 
		padding-top: 5px;
		overflow-y: scroll;
		
		@media #{$tablet} { max-height: 250px;}
		
		.select2-highlighted { color: #fff; }
		.select2-no-results { 
			background-color: #d4f4ff;
			padding-left: 13px;
		}
	}
	
	.select2-search {
		padding: 0;

		input { 
			@if $direction == "left" {
				background: url("../images/icons/search-icon.png") 96% 15px no-repeat #d4f4ff;
			} @else {
				background: url("../images/icons/search-icon.png") 4% 15px no-repeat #d4f4ff;
			}
			border-bottom: 1px solid nth($themeColors, 1);
			background-size: 16px 16px;
			color: #c0c0c2;
			padding: 5px 13px;
			height: 35px;
			line-height: 35px;
			font-family: $brandFontBold;

			.lang-el &{
				font-family: "Arial Black", Gadget, sans-serif;
			}
		}
	}
	
	.search-holder {
		margin-bottom: 35px;
		
		@media #{$tablet} { margin-bottom: 50px; }
		
		input { 
			background-color: #d4f4ff;
			@include placeholder($color: #bfbfbf);
			@media #{$tablet} { margin-right: 12px; }
		}
		
		.button {
			display: block;
			margin: 0 auto;
			
			
			@media #{$tablet} { 
				display: inline-block;
				margin: -3px 0 0 0;
				height: 52px;
				line-height: 26px;
			}
		}
	}
	
	.select2-drop { margin-top: -50px;}
	
	#countries-list {
		color: nth($themeColors, 2);
		font-family: $brandFontBold;
		font-size: 16px;
		line-height: 20px;
		text-transform: uppercase;

		.lang-el &{
			font-family: "Arial Black", Gadget, sans-serif;
		}

		.container {overflow: hidden;}
		
		
		ul {
			//@extend %cf;
			margin: 0;
			list-style: none;
			width: 100%;
			
			@media #{$tablet} { 
				width: 50%;
				float: left;
			}
			
			@media #{$mediumDesktop} { 
				width: 33.33%;
			}
		}
		
		li {
			box-sizing: border-box;
			padding: 10px;
			position: relative;
			min-height: 55px;
			overflow: hidden;
			transition: background-color 250ms;
			
			@media #{$tablet} { 
				height: 55px;
				padding: 10px 22px;
			}
			
			@media #{$mediumDesktop} { 
				padding: 10px 12px;
			}
			@media #{$desktop} { 
				padding: 10px 40px 10px 22px;
			}
			
			&:hover {
				background-color: nth($themeColors, 1);
				color: #fff;
				
				&:after {
					animation: floatingX 2500ms ease-in-out infinite;
				}
				
				& > a {
					color: #fff;
					text-decoration: none;
				}
				
				.country-social {
					display: table;
					height: 35px;
				}
			}
			
			&:after {
				@media #{$desktop} {
					border-bottom: 10px solid rgba(0, 0, 0, 0);
					border-left: 10px solid rgb(255, 255, 255);
					border-top: 10px solid rgba(0, 0, 0, 0);
					content: "";
					height: 0;
					margin: -10px 0 0 18px;
					position: absolute;
					right: 14px;
					top: 50%;
					vertical-align: middle;
					width: 0;
				}
			}
			
			& > a {
				color: nth($themeColors, 2);
				display: table;
				float: left;
				height: 35px;
				width: 90%;
				
				@media #{$tablet}{
					width: 120px;
				}
				@media #{$desktop}{
					width: 160px;
				}
				
				span {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}
		
		.country-social {
			display: none;
			float: left;
			
			@media #{$tablet}{ float: right; }
			
			a {
				background: url('../images/sprites/social-countries.png') top left no-repeat;
				display: inline-block;
				height: 24px;
				margin: 4px 8px 0 0;
				width: 26px;
				
				@media #{$tablet}{
					margin: 4px 0 0 4px;
				}
				@media #{$desktop}{
					margin: 4px 0 0 8px;
				}
				
				&:hover {
					background: url('../images/sprites/social-countries.png') top left no-repeat;
				}
				
				&.facebook { background-position: 10px -96px;}
				&.twitter { background-position: -39px -71px;}
				&.pinterest { background-position: -82px -47px;}
				&.youtube { background-position: -126px -24px;}
				&.instagram { background-position: -173px 2px;}
			}
		}
		
		footer {
			clear: both;
			background-color: #fff;
			margin: 50px auto 30px;
		}
		
		h3 { margin-bottom: 15px; }
	}


	/* == new design == */

	.countrieslist {

		@media #{$mediumDesktop} { 
			width: 17%;
			float: left;
			margin-left: 1%;
			margin-top: 30px;

			.button {
				display: none !important; // overwrite js styles
			}
		}

		@media #{$desktop} { 
			width: 24%;
		}

		ul {
			color: nth($themeColors, 2);
			font-family: $brandFontBold;
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;

			.lang-el &{
				font-family: "Arial Black", Gadget, sans-serif;
			}
			
			@extend %cf;
			margin: 0 0 20px 0;
			list-style: none;
		}

		li {

			width: 50%;
			float: left;
			
			@media #{$tablet} { width: 33%;}

			a {
				padding-bottom: 20px;
				display: inline-block;
				color: nth($themeColors, 2);
				&:hover {
					text-decoration: none;
					color:  nth($themeColors, 1);
				}
			}
		}
	}
	
	.continent li, .continents li {
		width: 100%;
		text-align: center;
		@media #{$tablet} { width: 50%; float: left; }
		
		@media #{$mediumDesktop} { 
			float: none; 
			text-align: left;
			width: 100%;
		}
		
	}
	
	@media #{$desktop} { 
		.continent li{
			float: left; 
			width: 50%;
		}
	}

	.mapwrap {
		position: relative;
		max-width: 1072px;
		margin: 0 auto;
		padding-bottom: 50%;

		display: none;

		@media #{$tablet} { 
			display: block;
			padding-bottom: 55%;
		}

		@media #{$mediumDesktop} { 
			width: 82%;
			padding-bottom: 50%;
			float: left;
		}

		@media #{$desktop} { 
			padding-bottom: 46%;
			width: 75%;

		}


		&.hidden {
			.map-overlay {
				opacity: 0;
			}
		}

		img {
			position: absolute;
			left:0;
			top:0;
			transition: opacity 500ms;
			max-width: 100%;
			height: auto;
		}

		.map-overlay.visible {
			opacity: 1;
		}

		area {
			display: block; // needed to add cursor style
			cursor: pointer;
		}
		
	}

	@mixin map-color-permanent($code) {
		&.continent-#{$code} {
			.map-overlay {
				opacity: 0;
			}
			#worldmap-overlay-#{$code} { opacity: 1; }
		}
	}
	section {
		@include map-color-permanent('na');
		@include map-color-permanent('sa');
		@include map-color-permanent('af');
		@include map-color-permanent('eu');
		@include map-color-permanent('as');
		@include map-color-permanent('au');
	}
	
	// Social popup`s altijd verbergen op deze pagina!
	.social-popup {
		display: none !important; // important to overrule js :s
	}
	
}

.countrylisted {
	color:  nth($themeColors, 2);
	text-align: center;
	line-height: 20px;
	margin-bottom: 50px;
	font-size: 16px;
	font-family: $brandFontBold;
	text-transform: uppercase;
	.button { margin-top: 20px;}
	.lang-el &{
		font-family: "Arial Black", Gadget, sans-serif;
	}
}

//	===========================
//	Custom fonts
//	===========================
@font-face {
	font-family: 'gt-walsheim';
	src: url('../fonts/GT-Walsheim-Pro-Ultra-Bold.eot');
	src: url('../fonts/GT-Walsheim-Pro-Ultra-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/GT-Walsheim-Pro-Ultra-Bold.woff') format('woff'),
	url('../fonts/GT-Walsheim-Pro-Ultra-Bold.ttf') format('truetype'),
	url('../fonts/GT-Walsheim-Pro-Ultra-Bold.svg#gt-walsheim') format('svg');
	font-weight: normal;
	font-style: normal;
}

