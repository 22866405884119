/*	============================== */
/*	Site specific general styles   */
/*	============================== */

//	==============================
//	Site specific placeholders
//	==============================

// Voorkomt dat er dingen geselecteerd kunnen worden.
body {
/*	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none*/

	background: #92d4f6;

	input {
/*		-webkit-user-select: all !important;
		-khtml-user-select: all !important;
		-moz-user-select: all !important;
		-ms-user-select: all !important;
		user-select: all !important;*/
	}
}
* {
	box-sizing: border-box;
	&:focus { outline:0; }
}

%column {
	float: left;

	&.first { margin-left: 0; }
	&.last { margin-right: 0; }
}

//	==============================
//	Typography
//	==============================

%brandFont {
	font-family: $brandFont;
	font-weight: normal;
}
%brandFontBold {
	font-family: $brandFontBold;
	font-weight: normal;
}

%introText {
	font-family: $brandFont;
	font-size: 16px;
	line-height: 24px;
}

%futuraLight{
	font-family:"Futura LT W01 Light Condensed";
	font-weight: normal;
}
%supernettLight{
	font-family:"Supernett cn W00 Light";
	font-weight: normal;
}
%supernettBold{
	font-family:"Supernett cn W00 Bold";
	font-weight: normal;
}
%opensans{
	font-family: 'open_sansregular';
}


body {
	font-family: $baseFont;
	font-size: 14px;
	font-weight: normal;
	line-height: 30px;
	-webkit-font-smoothing: antialiased;

	&.lang-ru, &.lang-el {
		font-family: $baseFontRu;
	}
}

#content > h1 {
	padding: 25px 0;
	margin: 0;

	@media #{$tablet} { padding: 35px 0; }
	@media #{$desktop} { padding: 45px 0; }
}

h1 {
	@extend %brandFontBold;
	background-color: #fff;
	box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.05);
	color: nth($themeColors, 2);
	display: block;
	font-size: 23px;
	line-height: 1.3em;
	margin: 0 0 26px;
	position: relative;
	//text-shadow: 0px 3px 0px nth($themeColors, 3);
	text-align: center;
	text-transform: uppercase;
	z-index: 90;

	.lang-el & {
		font-family: "Arial Black", Gadget, sans-serif;
	}

	.lang-vi & {
		font-family: $brandFontBoldVn;
	}

	@media #{$smallheight} { font-size: 28px; }
	@media #{$tablet} { font-size: 32px; }
	@media #{$desktop} { font-size: 36px; }


	a{
		color: nth($themeColors, 2);
		text-decoration: none;
		transition: color ease 400ms;

		&:hover{
			color: nth($themeColors, 1);
			text-decoration: none;
		}
	}



	section > div > &,
	section > &{
		padding: 25px 0 30px;
		margin: 0;

		@media #{$smallheight} { padding: 30px 0 30px; }
		@media #{$tablet} { padding: 40px 0 40px; }
		@media #{$desktop} { padding: 43px 0; }
	}

	// Squiggle under h1 only on home/front page
	.front &{
		&:before{
			display: block;
			position: absolute;
			left: 50%;
			content: "";
			bottom: 40px;
			z-index: 10;
			width: 150px;
			height: 5px;
			margin: 0 0 -20px -75px;


			@media #{$tablet}{
				margin: 0 0 -20px -100px;
				width: 200px;
				height: 6px;
				bottom: 50px;
			}
		}
	}

	// Arrow under h1
	&:after {
		width: 44px;
		height: 22px;
		display: block;
		position: absolute;
		margin-left: -22px;
		left: 50%;
		content: "";
		bottom: -22px;
		z-index: 10;
	}

	span {
		display: inline-block;

		@media #{$tablet} { display: inline-block;}

	}
	//a { color: nth($themeColors, 1); }

	+ p, p.subtitle {
		@extend %supernettBold;
		color: nth($themeColors, 1);
		font-weight: 300;
		line-height: 1.3em;
		margin: -2px auto 0px;
		//max-width: 390px;
		text-align: center;
		font-size: 17px;

		.lang-ru &, .lang-el &, .lang-bg &, .lang-mk & {
			font-family: $supernettAlternative;
			letter-spacing: -0.9px;
			font-size: 19px;
		}

		.lang-vi & {
			font-family: $brandFontVn;
		}

		@media #{$tablet}{
			margin: -2px auto 12px;
			font-size: 20px;
		}

		a {
			color: nth($themeColors, 2);
			font-weight: 500;
			transition: color 350ms ease;
			&:hover { color: nth($themeColors, 1); text-decoration: none;}
		}

	}
}

h2 {
	&, & a {
		@extend %brandFont;
		font-size: 20px;
		line-height: 1em;
		color: #000;


		.lang-vi & {
			font-family: $brandFontBoldVn;
		}
	}
}

h3, h4 {
	&, & a {
		@extend %brandFontBold;
		.lang-el & {
			font-family: "Arial Black", Gadget, sans-serif;
		}

		.lang-vi & {
			font-family: $brandFontBoldVn;
		}
	}
}

h3 {
	&, & a {
		@extend %brandFont;
		font-size: 20px;
		line-height: 24px;
		margin: 0 0 10px;

		.lang-vi & {
			font-family: $brandFontVn;
		}
	}

	p { margin: 0;}

}

.highlight h3 {
	font-size: 20px;
	font-family: $baseFont;
	line-height: 1.3em;
	font-weight: 300;

	@media #{$tablet}{ font-size: 28px;}
	@media #{$desktop}{ font-size: 39px;}

	strong {
		font-family: $brandFontBold;
		font-weight: 700;

		.lang-el &{
			font-family: "Arial Black", Gadget, sans-serif;
		}

		.lang-vi &{
			font-family: $brandFontBoldVn;
		}
	}
}

h4, h5 {
	&, & a {
		@extend %brandFontBold;
		font-size: 15px;
		line-height: 18px;
		.lang-el & {
			font-family: "Arial Black", Gadget, sans-serif;
		}

		.lang-vi & {
			font-family: $brandFontBoldVn;
		}
	}
}

h5 {
	&, & a {
		color: nth($themeColors, 2);
		text-transform: uppercase;
		font-size: 20px;
		margin-bottom: 25px;

		&:hover { text-decoration: none;}
	}
}

p {
	margin: 0 0 20px;
	line-height: 24px;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.intro, p.intro {
	@extend %introText;
	font-weight: normal;
}

.regular-header {
	padding: 30px 0;
	position: relative;
	z-index: 90;
	background: #fff;

	// make sure the related products ecommerce popup can be on top of the header
	.node-type-advanced-promotion & {
		z-index: 3;
	}

	// make sure the header preceding the related products block still gets a higher z-index than the related products block
	&.js-promotion-products-header {
		z-index: 5;
	}

	@media #{$tablet} {
		box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.05);
		padding: 30px 0 27px;

		.section-promoties &{
			padding: 25px 0 40px;
		}

		&:after {
			width: 44px;
			height: 22px;
			display: block;
			position: absolute;
			margin-left: -22px;
			left: 50%;
			content: "";
			bottom: -22px;
			z-index: 10;
		}

	}

	@media #{$desktop} { padding: 30px 0 27px; }


	.breadcrumb {
		display:none;
        top: -15px;
        @media #{$tablet} { top: -25px; }
        @media #{$mediumDesktop} { top: -15px; }
    }

	h1 {
		box-shadow: none;
		margin-bottom:10px;
		@media #{$maxMobileLandscape}{
		padding-bottom:10px;
		}
		padding-bottom: 20px;
		margin-bottom: 0;

		&:after {
			display: block;
			background: none;

			@media #{$maxMobileLandscape}{
				bottom: 5px;
			}
			bottom: 15px;
			width: 200px;
			height: 2px;
			border-top: 2px dashed #0ebde7;
			margin-left: -100px;
		}
		span:before { display: none;}
	}
}

.breadcrumb {
	display:none;

	@extend %opensans;
	color: #999;
	font-size: 14px;
	display: inline-block;
	margin: 0;
	text-align: #{$direction};
	width: 100%;
	//padding: 0 3%;
	max-width: 1280px;
	position: absolute;
	top: 80px;
	#{$direction}: 0;
	z-index: 100;

	.lang-ru &, .lang-el & {
		font-family: $brandFontRu;
	}

	@media #{$mediumDesktop} { top: 108px;}

	@media #{$desktop} {
		#{$direction}: 50%;
		margin-#{$direction}: -640px;
		width: 1280px;
		padding: 0;
	}

	a {
		color: #999; font-weight: 300; transition: color 350ms ease; text-transform: lowercase;
		&:hover { color: nth($themeColors, 1); text-decoration: none;}
	}
/*	span {
		font-size: 17px;
		font-weight: 900;
		text-transform: uppercase;
	}*/
}

//	==============================
//	General styles
//	==============================

%pseudo {
	content: '';
	display: block;
}

*, *:before, *:after { /* apply a natural box layout model to all elements */
	box-sizing: border-box;
 }

img {
	max-width: 100%;

	.fullscreen-img & {
		max-width: none;
	}
}

iframe {
	display: block;
}

//	===========================
//	Assets
//	===========================

// Header h1 and subtitle
// Volgens mij niet meer gebruikt...
/*.header-subtitle {
	height: 108px;

	@media #{$tablet} { height: 150px; }
	@media #{$desktop} { height: 175px; }

	h1 {
		padding: 25px 0 55px;
		margin: 0;
		z-index: 2;

		@media #{$tablet} { padding: 35px 0 75px; }
		@media #{$desktop} { padding: 45px 0 75px; }

		+ .subtitle {
			margin: -50px auto 0;
			position: relative;
			z-index: 2;

			@media #{$tablet} { margin-top: -65px; }
		}
	}
}*/


//	===========================
//	Placeholders
//	===========================

%theme-gradient-bg {
	//background: radial-gradient(ellipse at center,  rgba(255,255,255,0.65) 0%,rgba(255,255,255,0) 100%); /* W3C - werkt ook in ie10*/
	background-color: #0ebde7;
	background-color:nth($themeColors, 1);
}

//	===========================
//	Feedback messages
//	===========================
.alert {
	@extend %brandFontBold;
	background-color: #e32529;
	background-size: 4px 21px;
	background-position: 12px center;
	background-repeat: no-repeat;
	color: #fff !important;
	font-size: 15px;
	line-height: 18px;
	padding: 10px;
	.lang-el & {
		font-family: "Arial Black", Gadget, sans-serif;
	}
	.lang-vi & {
		font-family: $brandFontBoldVn;
	}
}

//	===========================
//	Custom fonts
//	===========================
@font-face {
    font-family: 'gt-walsheim';
    src: url('../fonts/GT-Walsheim-Pro-Ultra-Bold.eot');
    src: url('../fonts/GT-Walsheim-Pro-Ultra-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/GT-Walsheim-Pro-Ultra-Bold.woff') format('woff'),
         url('../fonts/GT-Walsheim-Pro-Ultra-Bold.ttf') format('truetype'),
         url('../fonts/GT-Walsheim-Pro-Ultra-Bold.svg#gt-walsheim') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face{
	font-family:"Futura LT W01 Light Condensed";
	src:url("../fonts/b1b295d2-89ce-43c4-bf6c-abbc7d26437e.eot?#iefix");
	src:url("../fonts/b1b295d2-89ce-43c4-bf6c-abbc7d26437e.eot?#iefix") format("eot"),url("../fonts/73956fa0-5cd8-4815-bacd-229aaac08ea2.woff2") format("woff2"),url("../fonts/8eb4bbf6-6c6e-46a0-a9cc-dab8c61069c6.woff") format("woff"),url("../fonts/87433a1d-86ba-46a1-b229-679d9ddbe1d8.ttf") format("truetype"),url("../fonts/22dbb88c-5e89-4b8b-9ea7-b60cc70d7ab3.svg#22dbb88c-5e89-4b8b-9ea7-b60cc70d7ab3") format("svg");
}
@font-face{
	font-family:"Supernett cn W00 Light";
	src:url("../fonts/9e0fff8f-a2d2-492b-b88e-40c9e83b460b.eot?#iefix");
	src:url("../fonts/9e0fff8f-a2d2-492b-b88e-40c9e83b460b.eot?#iefix") format("eot"),url("../fonts/1e73f70d-aab5-4050-a10a-cd8b67ad0323.woff2") format("woff2"),url("../fonts/1e79fe36-7868-427b-99ab-e7f0875d06c2.woff") format("woff"),url("../fonts/7c0705c9-d5f0-45a9-ad80-9d5150e7556b.ttf") format("truetype"),url("../fonts/493674a0-f76e-4e1c-8c29-72237d09dd2b.svg#493674a0-f76e-4e1c-8c29-72237d09dd2b") format("svg");
}
@font-face{
	font-family:"Supernett cn W00 Bold";
	src:url("../fonts/21991d25-436e-40aa-aa3a-ff9eb110ac87.eot?#iefix");
	src:url("../fonts/21991d25-436e-40aa-aa3a-ff9eb110ac87.eot?#iefix") format("eot"),url("../fonts/1bae1467-99a3-4ff3-a41f-f06494b2c05e.woff2") format("woff2"),url("../fonts/ba35a7a1-7e88-4e55-8ebd-54833f2bcf70.woff") format("woff"),url("../fonts/45ea2dcb-006f-484a-b777-141001c3b7d8.ttf") format("truetype"),url("../fonts/2fe7f19a-d396-4420-9c3d-a48fbca351c5.svg#2fe7f19a-d396-4420-9c3d-a48fbca351c5") format("svg");
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
	font-family: 'comic_reliefregular';
	src: url('../fonts/ComicRelief-webfont.eot');
	src: url('../fonts/ComicRelief-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/ComicRelief-webfont.woff') format('woff'),
	url('../fonts/ComicRelief-webfont.ttf') format('truetype'),
	url('../fonts/ComicRelief-webfont.svg#comic_reliefregular') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'iCiel Gotham Ultra';
	src: url('../fonts/iCielGotham-Ultra.eot');
	src: url('../fonts/iCielGotham-Ultra.eot?#iefix') format('embedded-opentype'),
	url('../fonts/iCielGotham-Ultra.woff') format('woff'),
	url('../fonts/iCielGotham-Ultra.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'iCiel Gotham Medium';
	src: url('../fonts/iCielGotham-Medium.eot');
	src: url('../fonts/iCielGotham-Medium.eot?#iefix') format('embedded-opentype'),
	url('../fonts/iCielGotham-Medium.woff') format('woff'),
	url('../fonts/iCielGotham-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'iCiel Gotham Thin';
	src: url('../fonts/iCielGotham-Thin.eot');
	src: url('../fonts/iCielGotham-Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/iCielGotham-Thin.woff') format('woff'),
	url('../fonts/iCielGotham-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
